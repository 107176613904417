<template>
  <div>
    <div :class="['form-fieldset', cardValid ? '' : 'border-warning']">
      <h3>Credit Card Information</h3>
      <div class="mb-3">
        <label class="form-label required" for="card-name">Name On Card</label>
        <input
          :class="['form-control', cardErrors.name ? 'is-invalid' : '']"
          id="card-name"
          type="text"
          v-model="name"
          @blur="validateName"
          ref="name"
        />
        <div class="invalid-feedback">
          Please enter the card owner's first and last name.
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6 mb-3 mb-md-0">
          <label class="form-label required" for="card-number">
            Card Number
          </label>
          <div class="input-group has-validation">
            <input
              :class="[
                'form-control',
                cardErrors.cardNumber ? 'is-invalid' : '',
              ]"
              id="card-number"
              type="text"
              v-model="card.cardNumber"
              @blur="validateCardNumber"
              ref="cardNumber"
            />
            <span v-if="cardBrandClass" class="input-group-text">
              <span :class="['payment', 'payment-xs', cardBrandClass]"></span>
            </span>
            <div class="invalid-feedback">
              Please enter a valid card number.
            </div>
          </div>
        </div>
        <div class="col-md-3 col-6">
          <label class="form-label required" for="expiration">
            Expiration (MM/YY)
          </label>
          <input
            type="text"
            :class="['form-control', cardErrors.expiration ? 'is-invalid' : '']"
            v-model="card.expiration"
            v-cardformat:formatCardExpiry
            @blur="validateExpiration"
            id="expiration"
            ref="expiration"
          />
          <div class="invalid-feedback">
            Please enter a valid expiration date.
          </div>
        </div>
        <div class="col-md-3 col-6">
          <label class="form-label required" for="card-code">CVV</label>
          <input
            :class="['form-control', cardErrors.cvv ? 'is-invalid' : '']"
            id="card-code"
            type="text"
            v-model="card.cvv"
            ref="cvv"
            @blur="validateCvv"
            v-cardformat:formatCardCVC
          />
          <div class="invalid-feedback">Please enter a valid CVV.</div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label required" for="card-code">Address</label>
        <input
          :class="['form-control', cardErrors.address ? 'is-invalid' : '']"
          id="address"
          type="text"
          v-model="address.address"
          @blur="validateAddress"
          ref="address"
        />
        <div class="invalid-feedback">Please enter an address.</div>
      </div>

      <div class="row mb-3">
        <div class="col-md-4 mb-3 mb-md-0">
          <label class="form-label required" for="billing-country">
            Country
          </label>

          <select class="form-select" v-model="address.country">
            <option
              v-for="country in countries"
              :key="`country-${country.countryID}`"
              :value="country.country"
            >
              {{ country.country }}
            </option>
          </select>
        </div>

        <div class="col-md-4 col-6">
          <label
            class="form-label required"
            for="billing-region"
            v-if="address.country == `United States`"
          >
            State
          </label>
          <label class="form-label required" for="billing-region" v-else>
            Region
          </label>
          <select
            class="form-select"
            id="billing-region"
            v-model="address.region"
            v-if="address.country == `United States`"
          >
            <option
              v-for="state in states"
              :key="`state-${state.stateID}`"
              :value="state.state"
            >
              {{ state.state }}
            </option>
          </select>
          <input
            class="form-control"
            v-model="address.region"
            type="text"
            v-else
          />
        </div>

        <div class="col-md-4 col-6">
          <label class="form-label required" for="billing-zip">
            Postal Code
          </label>
          <input
            :class="['form-control', cardErrors.zip ? 'is-invalid' : '']"
            id="billing-zip"
            type="text"
            v-model="address.zip"
            @blur="validateZip"
          />
          <div class="invalid-feedback">Please enter a valid zip code.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.payment-xs {
  width: 2.25rem;
  height: 1.25rem;
}
</style>

<script>
import BillingService from "../../../services/BillingService";
export default {
  name: "account.billing.billing-details-form",
  props: {
    value: Object,
  },
  data() {
    return {
      name: "",
      countries: [],
      states: [],
      card: {
        cardNumber: null,
        expiration: null,
        cvv: null,
      },
      address: {
        address: null,
        country: null,
        region: null,
        zip: null,
      },
      cardErrors: {
        name: false,
        cardNumber: false,
        expiration: false,
        cvv: false,
        address: false,
        zip: false,
      },
      cardValid: true,
      cardBrand: null,
    };
  },
  computed: {
    cardBrandClass() {
      return this.getBrandClass(this.cardBrand);
    },
    expirationMonths() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    },
    expirationYears() {
      let end = new Date().getFullYear() + 20;
      let years = [];
      for (let i = new Date().getFullYear(); i < end; i++) {
        years.push(i);
      }
      return years;
    },
  },
  methods: {
    async loadCountries() {
      this.countries = await BillingService.loadCountries();
      this.address.country = "United States";
    },
    async loadStates() {
      this.states = await BillingService.loadStates();
    },
    updateExpiration() {
      const expiration = this.card.expiration.split("/");
      this.value.expiration = `${expiration[0]
        .trim()
        .padStart(2, 0)}${expiration[1].trim()}`;
    },
    getBrandClass: function (brand) {
      let icon = "";
      brand = brand || "unknown";
      let cardBrandToClass = {
        visa: "payment-provider-visa",
        mastercard: "payment-provider-mastercard",
        amex: "payment-provider-americanexpress-dark",
        discover: "payment-provider-discover",
        diners: "payment-provider-dinersclub",
        jcb: "payment-provider-jcb",
        unknown: null,
      };

      if (cardBrandToClass[brand]) {
        icon = cardBrandToClass[brand];
      }

      return icon;
    },
    validateName() {
      let splitName = this.name.split(" ");
      if (this.name.length > 0 && splitName.length > 1) {
        this.cardErrors.name = false;
      } else {
        this.cardErrors.name = true;
      }

      this.validateCard();
    },
    validateCvv() {
      if (!this.$cardFormat.validateCardCVC(this.card.cvv)) {
        this.cardErrors.cvv = true;
      } else {
        this.cardErrors.cvv = false;
      }

      this.validateCard();
    },
    validateExpiration() {
      if (!this.$cardFormat.validateCardExpiry(this.card.expiration)) {
        this.cardErrors.expiration = true;
      } else {
        this.cardErrors.expiration = false;
      }

      this.validateCard();
    },
    validateCardNumber() {
      if (!this.$cardFormat.validateCardNumber(this.card.cardNumber)) {
        this.cardErrors.cardNumber = true;
      } else {
        this.cardErrors.cardNumber = false;
      }

      this.validateCard();
    },
    validateAddress() {
      if (this.address.address) {
        if (this.address.address.length > 0) {
          this.cardErrors.address = false;
        } else {
          this.cardErrors.address = true;
        }
      } else {
        this.cardErrors.address = true;
      }
      this.validateCard();
    },
    validateZip() {
      if (this.address.zip) {
        if (this.address.zip.length > 0) {
          this.cardErrors.zip = false;
        } else {
          this.cardErrors.zip = true;
        }
      } else {
        this.cardErrors.zip = true;
      }
      this.validateCard();
    },
    validateCard() {
      let dirty =
        this.name != "" &&
        this.card.cardNumber &&
        this.card.expiration &&
        this.card.cvv;
      if (!dirty) this.cardValid = true;
      let cardValid =
        !this.cardErrors.name &&
        !this.cardErrors.cardNumber &&
        !this.cardErrors.expiration &&
        !this.cardErrors.cvv &&
        !this.cardErrors.address &&
        !this.cardErrors.zip;
      this.cardValid = cardValid;
      this.value.cardValid = cardValid;
    },
  },
  watch: {
    "card.cardNumber"(val) {
      if (this.$cardFormat.validateCardNumber(val)) {
        this.value.cardNumber = val.split(" ").join("");
        this.$cardFormat.setCardType({
          currentTarget: this.$refs.cardNumber,
          value: val,
        });
      }

      this.validateCard();
    },
    "card.expiration"(val) {
      if (this.$cardFormat.validateCardExpiry(val)) {
        this.$refs.cvv.focus();
        this.updateExpiration();
      }

      this.validateCard();
    },
    "card.cvv"(val) {
      this.value.cardCode = val;

      this.validateCard();
    },
    name() {
      this.value.fullName = this.name;
      const split = this.name.split(" ");
      this.value.firstName = split[0];
      if (split.length > 1) {
        this.value.lastName = split[split.length - 1];
      }
      this.validateCard();
    },
    "address.address"() {
      this.value.address = this.address.address;
    },
    "address.zip"() {
      this.value.zip = this.address.zip;
    },
  },
  async mounted() {
    await this.loadCountries();
    await this.loadStates();
    this.$nextTick(() => {
      this.$refs.name.focus();
    });
  },
};
</script>
